<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs :items="breadcumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.href"
              class="text-subtitle-2 crumb-item"
              :disabled="item.disabled"
              exact
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-progress-linear
            :active="$store.state.isDataTableLoading"
            :indeterminate="$store.state.isDataTableLoading"
            absolute
            bottom
            color="deep-purple accent-4"
          ></v-progress-linear>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="filter_desc"
                  label="Description"
                  @keyup.enter="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mt-5"></v-divider>
            <v-row class="mt-5">
              <v-col cols="6" class="text-left">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  color="indigo"
                  @click="createNew()"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-btn
                  class="mr-2"
                  color="error"
                  elevation="2"
                  large
                  rounded
                  @click="submit()"
                >
                  <v-icon dark> mdi-magnify </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-snackbar
              v-model="snackbar.visible"
              :color="snackbar.color"
              :multi-line="snackbar.mode === 'multi-line'"
              :timeout="snackbar.timeout"
              :top="snackbar.position === 'top'"
            >
              <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                  <div>
                    <strong>{{ snackbar.title }}</strong>
                  </div>
                  <div>{{ snackbar.text }}</div>
                </v-layout>
              </v-layout>
              <v-btn
                v-if="snackbar.timeout === 0"
                icon
                @click="snackbar.visible = false"
              >
                <v-icon>clear</v-icon>
              </v-btn>
            </v-snackbar>
          </v-container>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="dataTabel"
                  :options.sync="opsi"
                  :server-items-length="totalItems"
                  loading-text="Please wait, retrieving data"
                  page-count="10"
                  class="elevation-1"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editItem(item)"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <dialog-input
          :visible="dialogVisible"
          :description="description"
          :btSave="btSave"
          :switchActive="switchActive"
          @is-saved="searchEmit"
        ></dialog-input>
        <!-- <dialog-input
          :visible="dialogVisible"
          :btSave="btSave"
          @is-saved="searchEmit"
        ></dialog-input> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DialogInput from "../../../../components/Layouts/Partials/kka/maintenance/master/dialog_machine_category.vue";

export default {
  components: {
    DialogInput,
  },
  data() {
    return {
      breadcumbs: [
          {
          text: "KKA",
          disabled: false,
          href: "/admin/kka",
          },
          {
          text: "KKA Maintenance",
          disabled: false,
          href: "/admin/kka/maintenance",
          },
      ],
      // snackbarLoading:this.$store.state.loading,
      snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false,
      },
      headers: [
        {
          text: "Machine Category ID",
          align: "start",
          value: "mach_categ_id",
        },
        { text: "Description", value: "descr" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      opsi: {},
      totalItems: 10,
      machCategId: "",
      dataTabel: [],
      // itemData:{},
      dialogVisible: false,
      filter_desc: "",
      btSave: "Save",
      switchActive: true,
      description: "",
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    searchEmit(payload) {
      console.log("search jalan = " + payload.isSaved );
      if (payload.isSaved) {
        this.submit();
      }
      this.dialogVisible = payload.visibility
      this.snackbar = payload.snackbar;
    },
    search() {
      // console.log("search jalan");
      this.submit();
    },

    createNew() {
      this.btSave = "Save";
      this.switchActive = true;
      this.dialogVisible = true;
      this.description = "";
    },

    editItem(item) {
      // this.itemData = item;
      // console.log("edititem = " + this.itemData.mach_categ_id);
      console.log("edititem = " + item.mach_categ_id);
      // console.log("edititem = " + this.itemData.descr);
      // console.log("edititem = " + this.itemData.active_flag);
      // this.description = item.descr;
      
      // if (item.active_flag == "Y") {
      //   this.switchActive = true;
      // } else {
      //   this.switchActive = false;
      // }
      this.$store.state.masterMachineCategory.machCategId = item.mach_categ_id;
      this.$store.state.masterMachineCategory.description = item.descr;
      if (item.active_flag == "Y") {
        this.$store.state.masterMachineCategory.switchActive = true;
      } else {
        this.$store.state.masterMachineCategory.switchActive = false;
      }
      // console.log('store = ' + this.$store.state.masterMachineCategory);
      // console.log('store = ' + this.$store.state.masterMachineCategory.description);
      // console.log('store = ' + this.$store.state.masterMachineCategory.switchActive);
      this.btSave = "Update";
      this.dialogVisible = true;
    },

    async submit(page = 1, itemsPerPage = 10) {
      // console.log("submit jalan");
      this.dataTabel = [];
      this.$store.state.isDataTableLoading = true;
      // console.log("mach categid = " + this.machCategId);
      await axios
        .get(
          `${process.env.VUE_APP_URL}/api/master/kka/machine_category?mach_categ_id=${this.machCategId}&description=${this.filter_desc}&page=${page > 1 ? page : ""}&pagination=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
            },
          }
        )
        .then((res) => {
          this.$store.state.isDataTableLoading = false;
          this.dataTabel = res.data.data;
          this.totalItems = res.data.total;
          // console.log("data table = ");
          // console.log(this.dataTabel);
          // console.log(this.totalItems);
        });
      // }
    },
  },
  watch: {
    opsi: {
      handler() {
        this.$store.state.isDataTableLoading = true;
        const { page, itemsPerPage } = this.opsi;
        console.log("page = " + this.page);
        console.log("items per page = " + this.itemsPerPage);
        console.log("opsi = " + this.opsi);
        // if (
        //   this.mill != "" &&
        //   // this.warehouse != "" &&
        //   // this.warehouse_dest != "" &&
        //   this.value != null
        // ) {
        this.submit(page, itemsPerPage);
        // }
      },
      deep: true,
    },
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>