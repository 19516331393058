<template>
    <!-- <v-dialog v-model="$store.state.dialog" max-width="500px" persistent> -->
    <v-dialog v-model="visible" max-width="500px" persistent>
        <v-card>
        <v-toolbar color="primary" dark> Create Machine Category Standart </v-toolbar>
        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="deep-purple accent-4"
        ></v-progress-linear>

        <v-card-text>
            <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <!-- <v-text-field
                        v-model="description"
                        
                        counter="200"
                        hint="Max 200 char"
                        label="Machine Category"
                    ></v-text-field> -->
                    <v-text-field
                        v-model="$store.state.masterMachineCategory.description"
                        
                        counter="200"
                        hint="Max 200 char"
                        label="Machine Standart"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-switch
                        v-model="$store.state.masterMachineCategory.switchActive"
                        :label="`Active: ${$store.state.masterMachineCategory.switchActive}`"
                    ></v-switch>
                </v-col>
            </v-row>
            </v-container>
            <!-- <small>*indicates required field</small> -->
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close"> Cancel </v-btn>
            <v-btn color="blue darken-1" text @click="save"> {{btSave}} </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    props: {
        visible:Boolean,
        btSave:"",
        // description: String,
        // switchActive: Boolean
    },
    data() {
        return{
            // machineCategory:"",
            // descr: this.description,
            // switchActive:true,
            loading:false,
            isSuccess:false,
            rules: {
                required: value => !!value || 'Required.',
                max: v => v.length <= 200 || 'Max 200 characters',
            },
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            // description: "",
            // switchActive: true,
        }
    },
    mounted(){
        console.log(this.visible);
    },
    methods:{
        clearVar(){
            // this.btSave="";
            // this.itemData={};
            // this.isSuccess = false;
            this.$store.state.masterMachineCategory.machCategId= "";
            this.$store.state.masterMachineCategory.description = "";
            this.$store.state.masterMachineCategory.switchActive=true;
        },
        close(){
            this.clearVar();
            // this.visibleDialog=false;
            this.$emit('is-saved', { isSaved: this.isSuccess, snackbar: this.snackbar, visibility:false });
        },

        async save(){
            console.log("description = " + this.description);
            // if (this.description === '') {
            //     console.log('masuk');
            // } else {
            var description = this.$store.state.masterMachineCategory.description;
            if (description !== '') {
                this.loading=true;
                // console.log("description = " + this.$store.state.masterMachineCategory.description);
                // console.log("isActive = " + this.$store.state.masterMachineCategory.switchActive);
                var activeFlag = (this.$store.state.masterMachineCategory.switchActive)?"Y":"N";
                // console.log("activeFlag = " + activeFlag);
                if(this.btSave == "Save"){
                    console.log("save");
                    await axios.post(`${process.env.VUE_APP_URL}/api/master/kka/machine_category`, {
                        // 'descr': this.description,
                        // 'active_flag': activeFlag,
                        'descr': this.$store.state.masterMachineCategory.description,
                        'active_flag': activeFlag,
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        this.loading = false;
                        this.isSuccess = true;
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Insert Machine Category",
                            visible: true
                        };
                        // this.visible = false;
                        this.clearVar();
                        this.$emit('is-saved', { isSaved: this.isSuccess, snackbar: this.snackbar, visibility:false});
                        this.isSuccess = false;
                    }).catch(err => {
                        this.loading = false
                        this.isSuccess = false;
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                        this.$emit('is-saved', { isSaved: this.isSuccess, snackbar: this.snackbar })
                    })
                } else {
                    console.log("update");
                    await axios.put(`${process.env.VUE_APP_URL}/api/master/kka/machine_category/update`, {
                        // 'descr': this.description,
                        // 'active_flag': activeFlag,
                        'mach_categ_id':this.$store.state.masterMachineCategory.machCategId,
                        'descr': this.$store.state.masterMachineCategory.description,
                        'active_flag': activeFlag,
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        this.loading = false;
                        this.isSuccess = true;
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Update Machine Category",
                            visible: true
                        };
                        // this.visible = false;
                        this.clearVar();
                        this.$emit('is-saved', { isSaved: this.isSuccess, snackbar: this.snackbar, visibility:false});
                        this.isSuccess = false;
                    }).catch(err => {
                        this.loading = false
                        this.isSuccess = false;
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                        this.$emit('is-saved', { isSaved: this.isSuccess, snackbar: this.snackbar })
                    })
                }
                
            }
        }
    },
    // computed: {
        // visibleDialog:{
		// 	// var visibleDialog = this.visible
        //     // console.log(visibleDialog);
        //     // return visibleDialog
        //     // this.$emit('update:visible', value)
        //     get () {
        //         console.log("visible = " + this.visible);
        //         return this.visible 
        //     },
        //     set (value) {
        //         console.log("visibleDialog = " + value); 
        //         this.$emit('update:visible', value)
        //     }
        // },
    //     descr: {
	// 		// var descr = this.description;
    //         // console.log(descr);
    //         // return descr;
    //         // this.$emit('update:visible', value)
    //         get () {
    //             console.log("descr = " + this.description);
    //             return this.description 
    //         },
    //         set (value) {
    //             console.log("visibleDialog = " + value); 
    //             this.$emit('update:description', value)
    //         }
    //     }
    // }
}
</script>
